<template>
  <div class="border border-solid d-theme-border-grey-light rounded relative overflow-hidden" id="email-app">

    <vx-card>


      <div class="flex flex-wrap items-center justify-end">
        <vs-input class="inputx w-1/2" label="Article Title" placeholder="loading..." v-model="title"/>

        <vs-button @click="promptForAction" class="ml-auto mt-2 bg-danger-gradient">Delete Article</vs-button>

        <vs-button @click="initiateArticleUpdate" class="ml-auto mt-2" color="primary">Save Changes</vs-button>
        <vs-button @click="loadArticle" class="ml-4 mt-2" color="warning" type="border">Reset</vs-button>
      </div>

      <br>

      <quill-editor v-model="content"></quill-editor>


    </vx-card>


  </div>
</template>

<script>

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import {quillEditor} from 'vue-quill-editor';

export default {
  name: 'BlogEdit',
  components: {
    quillEditor,
  },
  data() {
    return {
      id: this.$route.params.id,
      content: '',
      title: '',
      author: '',
      created: '',
    };
  },
  created() {
    this.loadArticle();
  },
  methods: {
    promptForAction() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm',
        text: 'Are you sure you want to delete this article?',
        accept: this.deleteArticle,
      });
    },
    deleteArticle() {
      this.$vs.loading();
      this.$http.delete(`blog/${this.id}`)
        .then(() => {
          this.$vs.loading.close();
          this.$router.push('/a/blogs');
          return this.$vs.notify({
            color: 'success',
            title: 'Deleted Article',
            text: 'Redirecting you to the blogs page',
          });
        })
        .catch(exception => {
          this.$vs.loading.close();
          if (exception.response) {
            return this.$vs.notify({
              title: 'Failed to load blog',
              text: 'An unknown error occurred while loading the blog. This has been reported.',
              color: 'danger',
              position: 'top-right',
            });
          }
        });

    },
    initiateArticleUpdate() {

      this.$vs.loading();

      this.$http
        .post(`blog/${this.id}`, {
          id: this.id,
          content: this.content,
          title: this.title,
        })
        .then(() => {
          this.$vs.loading.close();

          return this.$vs.notify({
            color: 'success',
            title: 'Saved Article',
            text: 'Your changes have been saved!',
          });
        })
        .catch(exception => {
          this.$vs.loading.close();

        });

    },
    loadArticle() {
      this.$vs.loading();

      this.$http.get(`blog/${this.id}`)
        .then(response => {
          this.$vs.loading.close();

          const article = response.data.data;
          this.content = article.content;
          this.title = article.title;
          this.created = article.created;
          this.author = article.author;
        })
        .catch(exception => {

          if (exception.response) {
            const response = exception.response;

            if (response.status === 404) {
              this.$vs.loading.close();
              this.$router.push('/a/blogs');
              return this.$vs.notify({
                title: 'Failed to load blog',
                text: 'The blog article you requested does not exist',
                color: 'danger',
              });
            }

          }

          return this.$vs.notify({
            title: 'Failed to load blog',
            text: 'An unknown error occurred while loading the blog. This has been reported.',
            color: 'danger',
            position: 'top-right',
          });

        });
    },
  },
};
</script>

<style scoped>

</style>
